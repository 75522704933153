$(document).foundation();

$(document).ready( function () {

	scaleVideo();
	initHeader();
	initLanding();
	iniOffCanvas();
	initAnimateInView();
	// initVideoHeader();







	$('.follow-us-link').on('click', function () {
		$('.follow-us')
			.empty()
			.load(
				$(this).data('follow-us-content'),
				function () {
					$('.follow-close').on('click', function () {
						$('.follow-us').fadeOut();
					});

					$('.hotel-selector').on('change', function () {

						$('.hotel-feeds').hide();

						$('.' + $(this).val() + '-row').show();

						/*if ($(".hotel-selector").find(":selected").data("hotel") == "cliveden") {
							$(".cliveden-link").show();
							$(".cliveden-row").show();
							$(".chewton-link").hide();
							$(".chewton-row").hide();
							$(".lygon-row").hide();
							$(".cadogan-row").hide();

						} else if ($(".hotel-selector").find(":selected").data("hotel") == "chewton") {
							$(".cliveden-link").hide();
							$(".cliveden-row").hide();
							$(".chewton-link").show();
							$(".chewton-row").show();
							$(".lygon-row").hide();
							$(".cadogan-row").hide();

						} else if ($(".hotel-selector").find(":selected").data("hotel") == "lygon") {
							$(".cliveden-link").hide();
							$(".cliveden-row").hide();
							$(".chewton-link").hide();
							$(".chewton-row").hide();
							$(".lygon-row").show();
							$(".cadogan-row").hide();

						} else if ($(".hotel-selector").find(":selected").data("hotel") == "chewton") {
							$(".cliveden-link").hide();
							$(".cliveden-row").hide();
							$(".chewton-link").hide();
							$(".chewton-row").hide();
							$(".lygon-row").hide();
							$(".cadogan-row").show();

						}*/
					});
				}
			 )
			 .fadeIn();
	});



	var divHeight = $('.main-header').height();
	$('.follow-us').css('top', divHeight+'px');

	$(window).on('resize', function () {
	    var divHeight = $('.main-header').height();
		$('.follow-us').css('top', divHeight+'px');
	});

	/*setHomeSliderHeight();
	$(window).on('resize', setHomeSliderHeight);*/
	$('.home-slider-inner, .sub-slider-inner').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		speed: 1000,
		arrows: true,
		fade: true
	});

	$('.home-scroll-arrow button').on('click', function () {
		$('html, body').animate({
			scrollTop: $('.home-slider').outerHeight()
		}, 750);
	});

	$('.header__book-button').on('click', function () {
		$('.header-book-panel').slideToggle();
		$('.header-gift-vouchers-panel').slideUp();
		return false;
	});

	/* $('.header__gift-button').on('click', function () {
		$('.header-gift-vouchers-panel').slideToggle();
		$('.header-book-panel').slideUp();
		return false;
	}); */

	$(document).on('click', function(event) {
  		if (!$(event.target).closest('.header-book-panel, .header-gift-vouchers-panel').length) {
    		// Hide the widget when clicked outside
			$('.header-book-panel, .header-gift-vouchers-panel').slideUp();
  		}
	});



	// vertical centering
	$('.vertical-center').each( function () {
		$(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
	});



	$('.booking-panel-hotels').on('change', function () {
		if ( $(this).val() === "Choose a Hotel" ) {
			$(this).next('.booking-panel-hotels-button').removeClass('show-this').attr('href', '#');
		} else {
			$(this).next('.booking-panel-hotels-button').addClass('show-this').attr('href', $(this).val());
		}
	});


	$('.gift-vouchers-panel-hotels').on('change', function () {
		if ( $(this).val() === "Choose a Hotel" ) {
			$(this).next('.booking-panel-gift-vouchers-button').removeClass('show-this').attr('href', '#');
		} else {
			$(this).next('.booking-panel-gift-vouchers-button').addClass('show-this').attr('href', $(this).val());
		}
	});











	// Set the ResDiary links to open in a popup
	$('a.hotel-book-online').each(function () {
		if ( $(this).attr('href').indexOf('widget.resdiary.com') > -1 ) {
			$(this).fancybox({
				type: 'iframe',
				width: '275',
				height: '450'
			});
		}
	});







	/* Careers Templates */
	$('.careers-landing__carousel-inner').slick({
		slidesToShow: 3,
		arrows: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 780,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.careers-landing__carousel-item a').on('click', function () {
		if ( $(this).attr('href').indexOf('#') > -1 ) {
			$($(this).attr('href')).foundation('open');
		}
	});

	$('.careers-listing__footer-carousel-inner').slick({
		arrows: true
	});




	/* gallery template */

	// Append the title as a caption
	$('.gallery__item a').each(function () {
		$(this).append('<div class="gallery__item-title">' + $(this).data('title') + '</div>' );
	});


	function positionGalleryArrows() {
		console.log('Image height:', $('.gallery-modal__image img').outerHeight() );
	}
	$(window).on('resize', positionGalleryArrows);

	// Dynamically load the gallery image into a reveal popup
	$('.gallery__item a').on('click', function (e) {
		e.preventDefault();

		var $clickedThumbnail = $(this);

		var imagelink = $(this).attr('href');
		var titletext = $(this).data('title');
		var descriptiontext = $(this).data('description');
		var booklink = $(this).data('booklink');
		var booktext = $(this).data('booktext');

		var modal = '<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal>';
		modal += '<div class="gallery-modal__image row"><div><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><img src="' + imagelink + '"/> <button class="gallery-modal__prev">Previous</button><button class="gallery-modal__next">Next</button></div>';
		modal += '<div class="row"><div class="pop-up-content columns large-6"><h2>' + titletext + '</h2></div>';
		modal += '<div class="description columns-large-6 text-right">' + descriptiontext + '</div></div>';

		/*if ((typeof booklink !== "undefined")&&(booklink !== "")) {
			modal += '<a class="booklink" href="' + booklink + '">' + booktext + '</a>';
			}*/

			modal += '</div></div>';


		var $modal = $(modal);

        $('body').append($modal);

        $('#gallery-modal').foundation();
        positionGalleryArrows();


        $('.gallery-modal__close').on('click', function () {
	        $modal.foundation('close');
	        $modal.remove();
        });







        $('.gallery-modal__next').on('click', function () {
	        $modal.foundation('close');
	        $modal.remove();
	        if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
		        $clickedThumbnail.parent().next(':visible').find('a').trigger('click');
	        } else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
		       $clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
		       $modal.foundation('close');
		       $modal.remove();
	        }
        });

        $('.gallery-modal__prev').on('click', function () {
	        $modal.foundation('close');
	        $modal.remove();
	        if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
		        $clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
	        } else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
		        $clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
		        $modal.foundation('close');
		        $modal.remove();
	        }
        });
        return false;
	});





	// Filter the gallery items
	var $_filterButtons = $('.gallery__filter-buttons button');
	var $_filterItems = $('.gallery__item');

    $_filterButtons.on('click', function() {

	    if ($(this).hasClass('all')) {
			$('.gallery__filter-buttons button.on').removeClass('on');
			$(this).addClass('on');
			$_filterItems.fadeIn();
			//refreshMasonry();
		    return;
	    }

	    if ($(this).hasClass('on')) {
		    $(this).removeClass('on');
	    } else {
		    $('.gallery__filter-buttons button.on').removeClass('on');
		    $(this).addClass('on');
	    }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
			$_filterItems.fadeIn();
			//refreshMasonry();
            return;
        }

        // Hide or show the elements that match the class
        $_filterItems.each(function() {
            if ($(this).is(currentCats)) {
                $(this).fadeIn();
            } else {
                $(this).fadeOut();
            }
		});


		//refreshMasonry();

		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);


	});

	// set masonry layout for visible items

	var $grid = $('.gallery__grid').masonry({
		columnWidth: '.gallery__item-sizer',
		itemSelector: '.gallery__item',
		percentPosition: true,
		horizontalOrder: true,
		fitWidth: true
	});


	// layout Masonry after each image loads
	$grid.imagesLoaded().progress( function() {
	  $grid.masonry('layout');
	});

	var refreshMasonry = function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);
	};


	$('button.all').click(function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);

	});



	// Mobile filter buttons
	$('.gallery__filter-buttons h3').on('click', function () {
		if (  $(window).innerWidth() <= 640 ) {
			$('.gallery__filter-buttons button, .arrow-down, .arrow-up').toggle();
		}
	});






	// Careers Landing Page Video - play pause

// $('#home-video-play-img, .iframe-wrap').click(function() {
// 		console.log('iframe clicked');
// 		var iframeMobile = $('iframe#video-mobile');
// 		var playerMobile = new Vimeo.Player(iframeMobile);
//
// 		playerMobile.getPaused().then(function(paused) {
// 				if ( paused == true ) {
// 						playerMobile.play();
// 						homeVideoPlay();
// 				} else {
// 						playerMobile.pause();
// 						homeVideoPause();
// 				}
// 		});
//
// });
//
// function homeVideoPlay() {
// 		$('#home-video-play-img').hide();
// }
//
// function homeVideoPause() {
// 		$('#home-video-play-img').show();
// }

//Careers  video volume button
////home - video - volume button - desktop

// $('.video-bar input[type="checkbox"]').on('change', function () {
// 		var iframeDesktop = $('iframe#desktop-video');
// 		var playerDesktop = new Vimeo.Player(iframeDesktop);
//
// 		playerDesktop.getVolume().then(function(volume) {
// 				if ( volume == 0  ) {
// 						playerDesktop.setVolume(0.66);
// 						$('#volumeSwitch').toggleClass('on');
// 						$('#volumeSwitch').toggleClass('off');
// 						$('#volumeOff').css('visibility', 'hidden');
// 						$('#volumeOn').css('visibility', 'visible');
// 						$("#volumeSwitch").prop('checked', false);
// 				} else {
// 						playerDesktop.setVolume(0);
// 						$('#volumeSwitch').toggleClass('on');
// 						$('#volumeSwitch').toggleClass('off');
// 						$('#volumeOff').css('visibility', 'visible');
// 						$('#volumeOn').css('visibility', 'hidden');
// 						$("#volumeSwitch").prop('checked', true);
// 				}
// 		})
//
// 		var iframeMobile = $('iframe#video-mobile');
// 		var playerMobile = new Vimeo.Player(iframeMobile);
//
// 		playerMobile.getVolume().then(function(volume) {
// 				if ( volume == 0  ) {
// 						playerMobile.setVolume(0.66);
// 						$('#volumeSwitch').toggleClass('on');
// 						$('#volumeSwitch').toggleClass('off');
// 						$('#volumeOffMobile').css('visibility', 'hidden');
// 						$('#volumeOnMobile').css('visibility', 'visible');
// 						$("#volumeSwitch").checked = true;
// 				} else {
// 						playerMobile.setVolume(0);
// 						$('#volumeSwitch').toggleClass('on');
// 						$('#volumeSwitch').toggleClass('off');
// 						$('#volumeOffMobile').css('visibility', 'visible');
// 						$('#volumeOnMobile').css('visibility', 'hidden');
// 						$("#volumeSwitch").checked = false;
// 				}
// 		})
// });


//Video Header Video Bar
	var iframePlayer = $('iframe#desktop-video');
	var player = new Vimeo.Player(iframePlayer);


		$('.video-bar').css('display', 'block');
		$('#volumeSwitch').toggleClass('off');
		$('#volumeOn').css('visibility', 'hidden');
		$('#volumeOff').css('visibility', 'visible');
		$("#volumeSwitch").prop('checked', true);

		$('.video-bar input[type="checkbox"]').on('change', function() {
				player.getVolume().then(function(volume) {
						if ( volume == 0  ) {
								player.setVolume(0.66);
								$('#volumeSwitch').toggleClass('on');
								$('#volumeSwitch').toggleClass('off');
								$('#volumeOff').css('visibility', 'hidden');
								$('#volumeOn').css('visibility', 'visible');
								$("#volumeSwitch").prop('checked', false);
						} else {
								player.setVolume(0);
								$('#volumeSwitch').toggleClass('on');
								$('#volumeSwitch').toggleClass('off');
								$('#volumeOff').css('visibility', 'visible');
								$('#volumeOn').css('visibility', 'hidden');
								$("#volumeSwitch").prop('checked', true);
						}
				})
		})





});


function setHomeSliderHeight() {
	$('.home-slider, .home-slider-inner').height( window.innerHeight - $('.header').outerHeight() );
}

function scaleVideo() {
    var viewportWidth = $(window).innerWidth();
    var viewportHeight = $(window).innerHeight();

    var $videoElement;
    if ( $('.videoWrapper #desktop-video').length > 0 ) {
        $videoElement = $('.videoWrapper #desktop-video');
    } else if ( $('.full-page-video video').length > 0 ) {
        $videoElement = $('.full-page-video video');
    } else {
        return;
    }

    var videoWidth = $videoElement.attr('width');
    var videoHeight = $videoElement.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = videoHeight / videoWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (videoWidth / videoHeight) * viewportHeight
    var newHeight = (videoHeight / videoWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', newWidth);
        $videoElement.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $videoElement.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $videoElement.css('width', viewportWidth);
        $videoElement.css('height', newHeight);
        $videoElement.css('left', '0');
        $videoElement.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', viewportWidth);
        $videoElement.css('left', '0');
        $videoElement.css('top', '0');
    }
}


function setHomeSliderHeight() {
    $('.home-slider, .home-slider-inner').height( window.innerHeight - $('.header').outerHeight() );
}


$(window).resize(function() {
    var width = $(document).width();
    if (width < 530) {
       $('.detail-slider video').trigger('pause');
    }
});

$(window).resize(function() {
    var width = $(document).width();
    if (width > 530) {
       $('.detail-slider video').trigger('play');
    }
});





function initHeader() {
	moveContentBelowHeader();
	$(window).on('resize', moveContentBelowHeader);

	$('.header__primary-nav-dropdown').each(function () {
		if ( $(this).find('li').length === 0 ) {
			$(this).remove();
		}
	});
}

function moveContentBelowHeader() {
	$('main').first().css('margin-top', $('.header').outerHeight());

	if ( $('.home-slider, .sub-slider, .video-header').length > 0 ) {
		$('main').first().css('padding-top', 0);
	}
}



function initLanding() {
	$('.landing__slider').slick({
		fade: true
	});

	$('.landing__content-toggle').on('click', function () {
		$(this).toggleClass('on');
		var $landingItem = $(this).parents('.landing__item');

		if ( $(this).hasClass('on') ) {
			$(this).text( $(this).data('less-text') );
			$landingItem.find('.landing__slider, .landing__content-more').slideDown(function () {
				$landingItem.find('.landing__slider').addClass('visible').slick('refresh');
			});
		} else {
			$(this).text( $(this).data('more-text') );
			$landingItem.find('.landing__slider').removeClass('visible');
			setTimeout(function () {
				$landingItem.find('.landing__slider, .landing__content-more').slideUp();
			}, 500);
		}
	});

	// $('.landing__group-anchor a').on('click', function () {
	// 	var anchorPos = $( 'a[name="' + $(this).attr('href').replace('#', '') + '"]' ).offset().top;

	// 	$('html,body').animate({
	// 		scrollTop: anchorPos - $('.header').outerHeight()
	// 	}, 500);
	// 	return false;
	// });
}


function iniOffCanvas() {
	$('.off-canvas__primary-nav > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('.off-canvas__primary-nav-dropdown').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			 $(this).find('.off-canvas__primary-nav-dropdown').prepend('<a class="off-canvas__primary-nav-dropdown-overview" href="' + overviewLink + '">Overview</a>');
		}
	});

	// Toggleable "dropwdown" nav
	$('.off-canvas__primary-nav > ul > li > a').on('click', function () {
		if ( $(this).next().hasClass('off-canvas__primary-nav-dropdown') ) {
			$(this).next().slideToggle();

			return false;
		}
	});
}

function initAnimateInView() {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
}

function animateElementsInView() {
	$('[data-animate-in-view]').each(function () {
		//console.log($(this));
		if ( isElementInViewport($(this)) ) {
			$(this).addClass( $(this).data('animate-in-view') );
		}
	});
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport (el) {
    //special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
	    ( (t >= 0) && (t <= h) ) ||
	    ( (b >= 0) && (b <= h) )
    ) {
	    return true;
    } else {
	    return false;
    }
}
